@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.intro {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 11;
  &-gradient-title {
    background: linear-gradient(73deg, #41ff54 0%, #12ecfa 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-animation {
    animation: move 5s linear infinite alternate;
  }
  &-left {
    &-btn-list {
      margin-top: 20px;
      display: flex;
      gap: 20px;

      @media screen and (max-width: 500px) {
        gap: 15px;
      }
    }
    &-content-main {
      display: flex;
      align-items: center;
      gap: 10px;
      h3 {
        color: #ffc50b;
      }
    }
    &-img {
      width: 100px;
      @media screen and (max-width: 1000px) {
        width: 60px;
      }
      @media screen and (max-width: 500px) {
        width: 50px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-right {
    &-wrapper {
      position: relative;
      width: 500px;
      @media screen and (max-width: 1250px) {
        width: 400px;
      }
      @media screen and (max-width: 1000px) {
        width: 330px;
      }
      @media screen and (max-width: 750px) {
        width: 250px;
      }
      @media screen and (max-width: 650px) {
        width: 200px;
      }
      @media screen and (max-width: 500px) {
        width: 180px;
      }
      .intro-img-bg {
        width: 100%;
        height: 100%;
        -webkit-animation: spin 5s linear infinite;
        -moz-animation: spin 5s linear infinite;
        animation: spin 5s linear infinite;
      }
      .intro-img-main {
        position: absolute;
        z-index: 999;
        width: 100%;
        top: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &-miner {
    margin: 15px 0;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
